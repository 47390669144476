<style lang="sass" scoped>
.container-detail
  position: absolute
  // top: 53px
  top: 0px
  right: 0
  width: calc(100vw - 300px)
  // height: calc(100vh - 100px)
  min-height: 100vh
  // background-color: #fff
  z-index: 4
  overflow: scroll
.container-search-pad
  padding-top: 53px
.container-search
  line-height: 1.7rem
  font-size: 12px
</style>
<template lang="pug">
div.container-detail.bg-light
  //- div
    .container-search-pad
      div.container-search.bg-white.shadow-sm.pl-2
        a.link-back(href='#' @click.prevent='close')
          span.text-dark(style='font-size: 18px; line-height: 50px;')
            b-icon-chevron-left
            strong(style='padding-left: 5px; font-size: 12px; line-height: 50px; color: #333') 목록 조회
        //- router-link.link-hover(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}`')
          strong.text-secondary.ml-4.ml-3 {{document.name}}
        strong.text-secondary.ml-4.ml-3 {{document.name}}
          span.text-muted.ml-2 조회

    div.bg-white.shadow-sm.m-3.p-4(style='min-height: 90vh')
      //- h5 {{document.name}}
        span.text-muted.ml-2 조회
      customer-record(
        :property_id='property_id'
        :document='document'
        :tags='tags'
        :document_id='document_id'
        :record_id='record_id'
        :record_row_id='record_row_id'
        @loaded='record_did_loaded'
        @updated='record_did_updated'
        @tag_updated='record_tag_did_updated'
      )
</template>

<script>
// import CustomerRecord from '@/components/CustomerRecord'

export default {
  name: 'index',
  props: ['property_id', 'document', 'tags', 'document_id', 'record_id', 'record_row_id', 'on_updated', 'fullpage_record_id'],
  components: {
    // CustomerRecord,
  },
  computed: {

  },
  watch: {
    // '$store.state.documents'() {
    //   this.load()
    // },
    // 'document_id'() {
    //   this.load()
    // },
    // 'record_id'() {
    //   this.load()
    // },
  },
  data() {
    return {
      done: false,
      saving: false,
      saving_text: '저장',
      // document: {},
      row: {},
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    // if (this.$store.state.documents) {
    //   this.load()
    // }
  },
  methods: {
    record_did_loaded() {
      console.log('record_did_loaded')
    },
    record_did_updated() {
      console.log('record_did_updated')
    },
    record_tag_did_updated() {
      console.log('record_tag_did_updated')
    },
    close() {
      this.$emit('before-close')
    },
  },
}
</script>
